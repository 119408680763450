<script lang="ts">
  // your script goes here

  import Button from '$lib/components/Button.svelte';
  import TextField from '$lib/components/TextField.svelte';
  import type { ClientUserStore } from 'shared/src';
  import SocialLoginButton from '$lib/components/SocialLoginButton.svelte';
  import { page } from '$app/stores';
  import MaterialIcon from '$lib/MaterialIcon.svelte';
  import { mdiCheckboxMarkedCircleOutline, mdiUpload } from '@mdi/js';
  import { closeModal } from '$lib/stores/modals.store';
  import LoadingSpinner from '$lib/components/LoadingSpinner.svelte';
  import { errorProvider } from '$lib/error-handling/errorProvider';
  import { createEventDispatcher } from 'svelte';
  import type { UserWithJoins } from 'shared/definitions/user-object-definitions';
  import type { UserRegistrationBody } from 'shared/definitions/local-registration-obj-definition';
  import { getApiPaths, getUserStore } from '$lib/stores/getUserStore';
  import { API } from 'shared/src/utils/api/api';
  import type { AuthFlowParameters } from 'shared/definitions/auth-object-definitions';

  /**
   * Will be undefined when used outside the app.
   */
  export let clientUserStore: ClientUserStore | undefined = undefined;

  export let postSocialLoginRedirect: string | undefined = undefined;

  export let isTeacher: boolean | null = null;

  export let parameters: AuthFlowParameters = {};

  export let doNotCloseModal = false;

  export let autofocus = false;

  const dispatch = createEventDispatcher<{
    registrationComplete: UserWithJoins;
  }>();

  let state: 'inputting' | 'waiting' | 'errored' | 'done' = 'inputting';
  const api = new API(getApiPaths());
  let registrationErrorMessage: string | null = null;
  let uploadState: 'pristine' | 'uploading' | 'errored' | 'complete' = 'pristine';
  let uploadError: string | null = null;
  let isValid = false;

  let firstName: string = '';
  let lastName: string = '';
  let email: string = '';
  let schoolName: string = '';
  let phoneNumber: string = '';
  let username: string = '';
  let password: string = '';
  let password2: string = '';
  let teacherUrl: string = '';
  let teacherImageUrl: string | null = '';
  $: password2IsValid = password === password2;

  const phoneRegex = '((\\D+)?\\d(\\D+)?){10,}';

  let form: HTMLFormElement;
  let showErrorsEvenWhenFocused = false;

  export function cleanup() {
    form.querySelectorAll('input').forEach((input) => {
      input.value = '';
    });
    uploadState = 'pristine';
    uploadError = null;
    isValid = false;
  }

  let registrationError: Partial<{
    status: number;
    general: string;
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    password: string;
    licenseSeats: string;
    phone: string;
    schoolName: string;
    teacherUrl: string;
  }> = {};

  async function registrationAttempt() {
    try {
      state = 'waiting';
      const registrationParams: UserRegistrationBody = {
        firstName,
        lastName,
        username,
        email,
        password,
        password2,
        isTeacher: !!isTeacher,
        phone: phoneNumber ? phoneNumber : undefined,
        schoolName: schoolName ? schoolName : undefined,
        teacherUrl: teacherUrl ? teacherUrl : undefined,
        teacherImageUrl: teacherImageUrl ?? undefined,
        ...parameters,
      };
      if (clientUserStore) {
        const userWithJoins = await clientUserStore.api.register(registrationParams);
        dispatch('registrationComplete', userWithJoins);
      } else {
        const userWithJoins = await api.register(registrationParams);
        // Now create a user store, to prevent having to reload the userWithJoins:
        await getUserStore({ userWithJoins });
        dispatch('registrationComplete', userWithJoins);
      }
      cleanup();
      state = 'done';
      if (!doNotCloseModal) {
        closeModal();
      }
    } catch (error: any) {
      state = 'errored';
      console.error(error);
      if (typeof error !== 'object') {
        registrationError = {
          general: 'Sorry, the uTheory server encountered an error. Please try again.',
        };
        return;
      }
      // We expect a 400 error message, if so, it'll have what we need:
      if (error?.status < 500) {
        registrationError = {
          ...error,
          general: error?.general || error?.message,
        };
        isValid = false;
        return;
      }

      registrationError = {
        general: 'Sorry, the uTheory server encountered an error. Please try again later.',
      };
      errorProvider.error(error);
    }
  }

  async function uploadImage(this: HTMLInputElement) {
    const OneMB = 1000 * 1000;
    const FiveMB = 5 * OneMB;
    if (!this.files || !this.files[0]) return;
    const file = this.files[0];
    if (file.size > FiveMB) {
      const size = Math.round((100 * file.size) / OneMB) / 100;
      uploadState = 'errored';
      uploadError = `File must be smaller than 5MB; yours is ${size}MB.`;
      return;
    }

    uploadError = null;
    uploadState = 'uploading';
    teacherImageUrl = null;
    try {
      teacherImageUrl = (await api.$upload(file.name, file)).url;
      uploadState = 'complete';
      // This is hacky, but, effectively we need to give time for the required
      // parameters on the inputs to change. Probably one cycle is enough, but
      // this is definitely safe and doesn't bother ux too much.
      setTimeout(onChange, 200);
    } catch (err: any) {
      uploadError = err?.html || err?.message || err?.text || err || 'Error uploading file.';
      uploadState = 'errored';
      errorProvider.error(err);
    }
  }

  function onInput(errorFieldName: keyof typeof registrationError) {
    if (registrationError[errorFieldName]) {
      registrationError = {
        ...registrationError,
        [errorFieldName]: undefined,
      };
    }
  }

  function onChange() {
    isValid = form.checkValidity();
    if (state !== 'waiting') state = 'inputting';
  }

  function loginGoogle() {
    api.loginGoogle({
      loginRedirect: parameters.loginRedirect || postSocialLoginRedirect || $page.url.pathname,
      isTeacher: !!isTeacher,
      ...parameters,
    });
  }

  function loginClever() {
    api.loginClever({
      loginRedirect: parameters.loginRedirect || postSocialLoginRedirect || $page.url.pathname,
      ...parameters,
    });
  }

  function loginFacebook() {
    api.loginFB({
      loginRedirect: parameters.loginRedirect || postSocialLoginRedirect || $page.url.pathname,
      isTeacher: !!isTeacher,
      ...parameters,
    });
  }

  let uploadInput: HTMLInputElement;
</script>

<div class="max-w-screen-xs sm:max-w-none mx-auto px-4 sm:px-8">
  <div
    class="
        flex
        flex-col
        space-y-4
        sm:space-y-0
        sm:flex-row
        sm:flex-grow
        sm:space-x-8
        items-center
        justify-around
        py-3
        "
  >
    <SocialLoginButton
      provider="Google"
      registration
      class="w-full max-w-xs"
      on:click={loginGoogle}
    />
    <SocialLoginButton
      provider="Clever"
      registration
      class="w-full max-w-xs"
      on:click={loginClever}
    />

    {#if !isTeacher}
      <SocialLoginButton
        provider="Facebook"
        registration
        class="w-full max-w-xs"
        on:click={loginFacebook}
      />
    {/if}
  </div>

  <div class="relative flex py-5 items-center w-1/2 mx-auto">
    <div class="flex-grow border-t border-gray-200" />
    <span class="flex-shrink mx-4 text-gray-200">or</span>
    <div class="flex-grow border-t border-gray-200" />
  </div>

  <form
    class="pb-3 mx-4"
    bind:this={form}
    on:submit={(evt) => {
      evt.preventDefault();
    }}
  >
    <div class="sm:flex sm:flex-row sm:space-x-8">
      <div class="w-full">
        <TextField
          {autofocus}
          class=""
          bind:value={firstName}
          on:input={() => onInput('firstName')}
          on:change={onChange}
          label="First name"
          autocomplete="given-name"
          required
          localValidationError={!!registrationError.firstName}
          customValidityMessage={registrationError.firstName ? registrationError.firstName : ''}
          showErrorWhenFocused={showErrorsEvenWhenFocused}
          showErrorWhenPristine={showErrorsEvenWhenFocused}
        />
        <TextField
          class=""
          bind:value={lastName}
          on:input={() => onInput('lastName')}
          on:change={onChange}
          label="Last name"
          autocomplete="family-name"
          required
          localValidationError={!!registrationError.lastName}
          customValidityMessage={registrationError.lastName ? registrationError.lastName : ''}
          showErrorWhenFocused={showErrorsEvenWhenFocused}
          showErrorWhenPristine={showErrorsEvenWhenFocused}
        />
        <TextField
          class=""
          type="email"
          bind:value={email}
          on:input={() => onInput('email')}
          on:change={onChange}
          label="Email"
          autocomplete="email"
          required
          localValidationError={!!registrationError.email}
          customValidityMessage={registrationError.email
            ? registrationError.email
            : '' || 'Please enter a valid email.'}
          showErrorWhenFocused={showErrorsEvenWhenFocused}
          showErrorWhenPristine={showErrorsEvenWhenFocused}
        />
        {#if isTeacher}
          {#if !parameters.institutionInviteToken}
            <TextField
              class="w-full "
              bind:value={schoolName}
              on:input={() => onInput('schoolName')}
              on:change={onChange}
              label="School name"
              autocomplete="organization"
              required={isTeacher && !parameters.institutionInviteToken}
              localValidationError={!!registrationError.schoolName}
              customValidityMessage={registrationError.schoolName
                ? registrationError.schoolName
                : ''}
              showErrorWhenFocused={showErrorsEvenWhenFocused}
              showErrorWhenPristine={showErrorsEvenWhenFocused}
            />
          {/if}
          <TextField
            class="w-full "
            bind:value={phoneNumber}
            on:input={() => onInput('phone')}
            on:change={onChange}
            label="Phone number"
            autocomplete="tel"
            type="tel"
            pattern={phoneRegex}
            required={isTeacher}
            localValidationError={!!registrationError.phone}
            customValidityMessage={registrationError.phone
              ? registrationError.phone
              : '' || 'Phone number is too short.'}
            showErrorWhenFocused={showErrorsEvenWhenFocused}
            showErrorWhenPristine={showErrorsEvenWhenFocused}
          />
        {/if}
      </div>
      <div class="w-full">
        <TextField
          class=""
          bind:value={username}
          on:input={() => onInput('username')}
          on:change={onChange}
          label="Username"
          autocomplete="username"
          required
          localValidationError={!!registrationError.username}
          customValidityMessage={registrationError.username ? registrationError.username : ''}
          showErrorWhenFocused={showErrorsEvenWhenFocused}
          showErrorWhenPristine={showErrorsEvenWhenFocused}
        />
        <TextField
          class=""
          type="password"
          bind:value={password}
          on:input={() => onInput('password')}
          on:change={onChange}
          label="Password"
          autocomplete="new-password"
          required
          minLength={6}
          customValidityMessage={registrationError.password
            ? registrationError.password
            : '' || 'Must be at least 6 characters.'}
          localValidationError={!!registrationError.password}
          showErrorWhenFocused={showErrorsEvenWhenFocused}
          showErrorWhenPristine={showErrorsEvenWhenFocused}
        />
        <TextField
          class=""
          type="password"
          bind:value={password2}
          on:input={() => onInput('password')}
          on:change={onChange}
          label="Password again"
          autocomplete="new-password"
          customValidityMessage="Passwords do not match"
          localValidationError={!password2IsValid}
          required
          showErrorWhenFocused={showErrorsEvenWhenFocused}
          showErrorWhenPristine={showErrorsEvenWhenFocused}
        />
        {#if isTeacher && !parameters.institutionInviteToken}
          <TextField
            class="w-full"
            bind:value={teacherUrl}
            on:input={() => onInput('teacherUrl')}
            on:change={onChange}
            label="School URL with your profile"
            autocomplete="url"
            required={!teacherImageUrl}
            localValidationError={!!registrationError.teacherUrl}
            customValidityMessage={registrationError.teacherUrl
              ? registrationError.teacherUrl
              : teacherUrl || teacherImageUrl
              ? ''
              : 'URL or photo required.'}
            showErrorWhenFocused={showErrorsEvenWhenFocused}
            showErrorWhenPristine={showErrorsEvenWhenFocused}
          />
          <div class="text-gray-300 high-contrast:text-gray font-light text-sm text-center">
            <input
              id="registration-upload-teacher-verification-image"
              class="hidden"
              type="file"
              bind:this={uploadInput}
              on:change={uploadImage}
              required={!teacherUrl && !teacherImageUrl}
            />
            <button
              on:click={(evt) => {
                uploadInput.click();
                evt.preventDefault();
              }}
            >
              Or
              <span class="text-cyan-600">
                {#if uploadState === 'pristine' || uploadState === 'errored'}
                  <MaterialIcon label="Upload Icon" path={mdiUpload} />Upload
                {:else if uploadState === 'uploading'}
                  <LoadingSpinner />
                {:else if uploadState === 'complete'}
                  <MaterialIcon label="Upload Complete" path={mdiCheckboxMarkedCircleOutline} />
                  Change
                {/if}
              </span>

              Faculty ID photo
              <br />
              <span class=""> or other evidence of your status as a teacher </span>
              {#if uploadError}
                <div class="text-red">
                  {uploadError}
                </div>
              {/if}
            </button>
          </div>
        {/if}
      </div>
    </div>
    <div class="text-center text-red">{registrationError.general || ''}</div>
    <div class="text-center">
      <!-- 
          -- IMPORTANT: we omit type="submit" because of the bug below
          -- https://github.com/tailwindlabs/tailwindcss/issues/6602
          -- until it is fixed, background color classes don't work on buttons
          -- with their type specified.
          -->
      <Button
        disabled={!isValid || state === 'waiting' || state === 'done'}
        on:click={registrationAttempt}
        on:disabledClick={() => {
          showErrorsEvenWhenFocused = true;
        }}
        class="block w-1/2 min-w-max h-12 text-sm font-normal mx-auto mt-4"
      >
        <span class="px-4 xs:px-20">
          {#if state !== 'waiting' && state !== 'done'}
            Sign up
          {:else}
            <LoadingSpinner />
          {/if}
        </span></Button
      >
    </div>
  </form>
</div>
{#if registrationErrorMessage}
  <div class="text-red-500">
    {registrationErrorMessage}
  </div>
{/if}
