<script lang="ts">
  // your script goes here

  import type { ClientUserStore } from 'shared/src';
  import { createEventDispatcher } from 'svelte';
  import SocialLoginButton from '$lib/components/SocialLoginButton.svelte';
  import { API } from 'shared/src/utils/api/api';
  import { getApiPaths } from '$lib/stores/getUserStore';
  import type { UserWithJoins } from 'shared/definitions/user-object-definitions';
  import type { AuthFlowParameters } from 'shared/definitions/auth-object-definitions';
  import LocalLoginForm from './LocalLoginForm.svelte';

  /**
   * Will be undefined when used outside the app.
   */
  export let clientUserStore: ClientUserStore | undefined = undefined;

  /**
   * When a user is logged out in another window, we don't show them the
   * "New to uTheory? Register..." option. If not hidden, you must listen
   * for 'register' events and respond appropriately.
   */
  export let hideRegistrationOption: boolean = false;

  /**
   * Whether to hide the "Forgot your username or password?" prompt.
   * If not hidden, you must listen for 'forgottenPassword' events and
   * handle appropriately.
   */
  export let hideForgottenPasswordOption: boolean = false;

  /**
   * If included, redirect to this URL following a social login.
   */
  export let postSocialLoginRedirectUrl: string | undefined = undefined;

  export let parameters: AuthFlowParameters = {};

  export let autofocus = false;

  const api = new API(getApiPaths());

  const dispatch = createEventDispatcher<{
    loginComplete: UserWithJoins;
    forgottenPassword: null;
    register: null;
  }>();

  function loginGoogle() {
    api.loginGoogle({
      loginRedirect: postSocialLoginRedirectUrl,
      ...parameters,
    });
  }

  function loginClever() {
    api.loginClever({
      loginRedirect: postSocialLoginRedirectUrl,
      ...parameters,
    });
  }

  function loginFacebook() {
    api.loginFB({
      loginRedirect: postSocialLoginRedirectUrl,
      ...parameters,
    });
  }
</script>

<div class="max-w-screen-xs sm:max-w-none mx-auto">
  <div
    class="flex
  flex-col
  sm:flex-row
  items-stretch
  sm:space-x-8
  px-4
  sm:px-8
  pt-6"
  >
    <LocalLoginForm {clientUserStore} {parameters} {autofocus} on:loginComplete />
    <div
      class="
          hidden
          sm:flex
          w-0
          overflow-visible
          items-center
          relative
          border-r
          border-gray-200
        "
    >
      <div class="or text-gray-200">or</div>
    </div>
    <div class="sm:hidden relative flex py-5 items-center">
      <div class="flex-grow border-t border-gray-200" />
      <span class="flex-shrink mx-4 text-gray-200">or</span>
      <div class="flex-grow border-t border-gray-200" />
    </div>
    <!-- <div class="sm:hidden horizontal-or">or</div>
      <div class="hidden xs:block absolute or">or</div> -->
    <div class="flex flex-grow flex-col space-y-4 items-center justify-around py-3">
      <SocialLoginButton provider="Google" class="w-full" on:click={loginGoogle}
        >Sign in with Google</SocialLoginButton
      >
      <SocialLoginButton provider="Clever" class="w-full" on:click={loginClever}
        >Sign in with Clever</SocialLoginButton
      >
      <SocialLoginButton provider="Facebook" class="w-full" on:click={loginFacebook}
        >Sign in with Facebook</SocialLoginButton
      >
    </div>
  </div>
</div>
{#if !hideRegistrationOption}
  <div class="text-center text-gray-300 high-contrast:text-gray font-thin text-lg pt-1">
    New to uTheory? <button
      class="text-blue-light"
      on:click={() => {
        dispatch('register');
      }}>Register</button
    >
  </div>
{/if}

{#if !hideForgottenPasswordOption}
  <div class="text-center text-gray-300 high-contrast:text-gray font-thin text-base pt-1">
    Forgot your <button
      class="text-blue-light"
      on:click={() => {
        dispatch('forgottenPassword');
      }}>username</button
    >
    or
    <button
      class="text-blue-light"
      on:click={() => {
        dispatch('forgottenPassword');
      }}>password</button
    >?
  </div>
{/if}

<style lang="postcss">
  .or {
    text-align: center;
    position: absolute;
    background: white;
    line-height: 1em;
    top: calc(50% - 1.25em);
    left: -1em;
    width: 2em;
    padding: 0.5em 0.5em;
  }

  .horizontal-or {
    border-bottom: 1px solid #ccc;
    font-size: 1.25em;
    line-height: 1.9;
    text-align: center;
    color: var(--grey-400-as-dark);
    max-width: 300px;
    margin: auto;
    -webkit-transform: translateY(-60%);
    -moz-transform: translateY(-60%);
    -ms-transform: translateY(-60%);
    -o-transform: translateY(-60%);
    transform: translateY(-60%);

    .text {
      background: white;
      display: inline-block;
      width: 30px;
      height: 37px;
      -webkit-transform: translateY(50%);
      -moz-transform: translateY(50%);
      -ms-transform: translateY(50%);
      -o-transform: translateY(50%);
      transform: translateY(50%);
    }
  }
</style>
